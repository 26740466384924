/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { Element, scroller } from 'react-scroll'
import { useDimensions } from '../../common/mamager/ScreenManager'
import Header from '../../common/components/header'
import { useLocation } from 'react-router'
import Footer from '../../common/components/footer'
import HotNews from '../../common/components/hotNews'
import Ads from '../../common/components/ads'
import NewsList from '../../common/components/newsList'

export default function Home() {

    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const menu = params.get("menu")

    const { width } = useDimensions()

    const [previousWork, setPreviousWork] = useState([])

    const [loading, setLoading] = useState(false)

    const genScroll = () => {
        if (menu === "news") {
            scroller.scrollTo("news", {
                duration: 1500,
                delay: 100,
                smooth: true,
                offset: -78,
            })
        } 
    }

    useEffect(() => {
        genScroll()
    }, [])

    return (
        <>
            <Header />
            
            <div style={{ paddingTop: 70, paddingBottom: 0, background: 'black' }}>
                <Element name="hotNews">
                    <HotNews />
                </Element>
                <Element name="ads">
                    <Ads />
                </Element>
                <Element name="news">
                    <NewsList />
                </Element>
            </div>

            <Footer />
        </>
    )
}
