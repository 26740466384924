/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
import { useState, useEffect } from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { serverUrl } from "../../constants"
import parse from 'html-react-parser'
import Header from '../../common/components/header'
import Footer from '../../common/components/footer'
import LoadingBgBlack from "../../common/components/loading/bgBlack"
import { Flex } from "antd"
import moment from 'moment'
import SEO from '../../common/components/seo'

import 'moment/locale/th'
import './css/index.scss'

const NewsDetail = () => {
    const { id } = useParams(); // Get the news ID from the URL
    const [news, setNews] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [ads, setAds] = useState(null);

    useEffect(() => {
        const fetchNewsDetail = async () => {
            try {
                const response = await axios.get(serverUrl + `/api/newsDetailPage/${id}`)
                setNews(response.data.formData[0]);
                setLoading(false);
            } catch (error) {
                setError(error.message);
                setLoading(false);
            }
            try {
                const response = await axios.post(serverUrl + `/api/newsDetailPage/addCountViewNews/${id}`)
            } catch (error) {
            }

            try {
                const response = await fetch(serverUrl + '/api/homePage/adsBanners')
                const data = await response.json()
                const adses = data.formData
                if (adses.length > 0) {
                    let ads = adses[Math.floor(Math.random() * adses.length)]
                    setAds(ads)
                } else {
                }
            } catch (error) {
                console.error('Error fetching the news:', error)
            }
        };

        fetchNewsDetail()
    }, [id])

    return (
        <>
            <Header />
            <div style={{ paddingTop: 70, paddingBottom: 24, background: 'black', display: 'block' }}>
                {!loading ?
                    <>
                        <SEO
                            title={news?.title}
                            description={news?.detail}
                            name={"KK WEEKLY NEWS"}
                            type={"article"}
                            image={news?.imageThumbnail}
                        />
                        <div className='container'>
                            <div className='news-title-container'>
                                <Flex gap="middle" justify='space-between' align='center'>
                                    <div>
                                        <div className='title'>
                                            <label>{news?.title}</label>
                                        </div>
                                        <div className='date'>สร้างเมื่อวันที่ {moment(news.createdAt).format('MMMM Do YYYY, HH:mm:ss')}</div>
                                    </div>
                                    <div>
                                        <div className='share-title'>แชร์</div>
                                        <Flex gap="middle" align='center' justify='flex-start'>
                                            <a href={'https://www.facebook.com/sharer/sharer.php?u=' + window.location.href} target="_blank">
                                                <img src='/assets/images/logo/facebook-logo.png' className='share-logo'></img>
                                            </a>
                                            <a href={'https://twitter.com/intent/tweet?url=' + window.location.href} target="_blank">
                                                <img src='/assets/images/logo/twitter.png' className='share-logo'></img>
                                            </a>
                                            <a href={'https://social-plugins.line.me/lineit/share?url=' + window.location.href} target="_blank">
                                                <img src='/assets/images/logo/line-logo.png' className='share-logo'></img>
                                            </a>
                                        </Flex>
                                    </div>
                                </Flex>
                            </div>
                            <div className='news-detail-container'>
                                <div className='content-container'>
                                    <div className='image-container'>
                                        <img src={news?.imageThumbnail}></img>
                                    </div>
                                    <div className='detail'>
                                        <label>{news?.detail ? parse(news?.detail ?? "-") : "-"}</label>
                                    </div>
                                </div>
                                {ads ?
                                    <div className='random-ads'>
                                        <img src={ads.image}></img>
                                    </div>
                                    : []
                                }
                            </div>

                        </div>
                    </>
                    :
                    <LoadingBgBlack loading={loading} />
                }
            </div>
            <Footer />
        </>
    )
}

export default NewsDetail