/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect, useRef } from 'react'
import {
    Table,
    Row,
    Col,
    Button,
    Form,
    Modal,
    Input,
    Popconfirm,
    Select,
    Upload,
    InputNumber,
    Radio,
    // DatePicker 
} from 'antd'
import { FaCog } from "react-icons/fa"
import moment from 'moment'
import { Icon } from '@iconify/react'
import { Notification } from '../../../../common/components/notification'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import { apiServerUrl, server } from '../../../../constants'
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons'
import { deleteAdsBannersByIdFetch, getAdsBannersFetch, insertAdsBannersFetch, updateAdsBannersFetch } from './API'

const { TextArea } = Input;
const { Option } = Select
// const { RangePicker } = DatePicker

const columns = [
    {
        title: 'No.',
        dataIndex: 'index',
        width: "10%",
    },
    {
        title: 'ชื่อโฆษณา',
        dataIndex: 'name',
        width: "20%",
    },
    {
        title: 'รายการโฆษณา',
        dataIndex: 'image',
        width: "30%",
        render: (text, record) => {
            return (
                <>
                    <img src={record.image} alt={record.name} style={{ width: 100 }} />
                </>
            )
        }
    },
    {
        title: 'สถานะ',
        width: "10%",
        render: (text, record) => {
            return (
                <>
                    {record.isActive ?
                        <label>แสดงข้อมูล</label>
                        :
                        <label>ปิดแสดงข้อมูล</label>
                    }
                </>
            )
        }
    },
    {
        title: 'วันที่สร้าง',
        dataIndex: 'createdAt',
        width: "10%",
    },
    {
        title: 'เเก้ไขล่าสุด',
        dataIndex: 'updatedAt',
        width: "10%",
    },
    {
        title: <FaCog />,
        dataIndex: 'operator',
        align: "center",
        width: "10%",
    },
]

const formatDate = "DD/MM/YYYY"

export default function VehicleManage(props) {

    const [list, setList] = useState([])
    const [loading, setLoading] = useState(true)

    const [formAdsBanner] = Form.useForm()
    // const [formPropertyGallery] = Form.useForm()
    const [formSearch] = Form.useForm()

    const accessToken = sessionStorage.getItem("accessToken")

    const [adsBannerId, setAdsBannerId] = useState()

    const [imageURL, setImageURL] = useState({
        loading: false,
        imageUrl: null
    })

    const pageCurrentRef = useRef(1)
    const [pageSize, setPageSize] = useState(10)
    const [total, setTotal] = useState(0)
    const searchNameRef = useRef("")

    const [modalAdsBanner, setModalAdsBanner] = useState({
        isShow: false,
        title: null
    })

    const optionAdsBannerImage = {
        name: 'file',
        action: `${apiServerUrl}${server.UPLOAD_FILE}?type=ads-banner`,
        data: {
            name: "admin"
        },
        headers: {
            'x-access-token': accessToken
        },
        onChange(info) {

            if (info.file.status !== 'uploading') {
                let result = info.file.response
                if (result?.isSuccess) {
                    // console.log("A1 --- : ", result)
                    setImageURL({
                        imageUrl: result.formData.fileUrl,
                        loading: false
                    })
                }
            } else {
                setImageURL({
                    imageUrl: imageURL.imageUrl,
                    loading: true
                })
            }

            if (info.file.status === 'done') {
                Notification("success", "เเจ้งเตือน!", "อัพโหลดรูปภาพสำเร็จ")
            } else if (info.file.status === 'error') {
                Notification("error", "เเจ้งเตือน!", "อัพโหลดรูปภาพไม่สำเร็จ กรุณาลองใหม่อีกครั้ง")
            }
        },
        progress: {
            strokeColor: {
                '0%': '#FF7F00',
                '100%': '#FF7F00',
            },
            strokeWidth: 3,
            width: '10%',
            format: percent => `${parseFloat(percent.toFixed(0))}%`,
        }
    }

    const getAdsBannersAll = async (name) => {
        setLoading(true)

        let param = {
            name,
            isActive: "",
            page: pageCurrentRef.current,
            size: pageSize
        }
        const result = await getAdsBannersFetch(param, null, accessToken)
        // console.log("getAdsBannersFetch : ", result)
        setTotal(result?.totalItems)
        let tempList = []
        result?.results?.map((val, index) => {
            tempList.push({
                index: (pageCurrentRef.current * pageSize - 10) + (index + 1),
                image: val.image, // รูปภาพหน้าปก
                name: val.name, // 
                isActive: val.isActive, // สถานะการใช้งาน
                createdAt: val.createdAt ? moment(val.createdAt).format(formatDate) : "-",
                updatedAt: val.updatedAt ? moment(val.updatedAt).format(formatDate) : "-",
                operator:
                    <>
                        <Button
                            style={{
                                width: 35,
                                backgroundColor: "orange",
                                border: "1px solid orange",
                                color: 'white',
                                borderRadius: 50
                            }}
                            onClick={async () => {
                                formAdsBanner.setFieldsValue({
                                    id: val.id, // 
                                    name: val.name, // 
                                    isActive: val.isActive, // 
                                })

                                setImageURL({
                                    loading: false,
                                    imageUrl: val.image	
                                })

                                setAdsBannerId(val.id)
                                // console.log("adsBannerId : ", val.id)

                                setModalAdsBanner({ isShow: true, title: "edit" })
                            }}
                        >
                            <div style={{ marginTop: 0, marginLeft: -8 }}>
                                <Icon icon="typcn:edit" style={{ color: "white", width: 20, height: 20 }} />
                            </div>
                        </Button>{"  "}

                        <Popconfirm
                            title="คุณยืนยันลบหรือไม่ ?"
                            okText={<span style={{ width: 50 }}>ใช่</span>}
                            onConfirm={async () => {
                                await handleAdsBannersDelete(val.id)

                                // reload
                                await getAdsBannersAll("")
                            }}
                            cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                        >
                            <Button
                                danger
                                type="primary"
                                style={{
                                    width: 35,
                                    borderRadius: 50
                                }}
                            >
                                <div style={{ marginTop: 0, marginLeft: -8 }}>
                                    <Icon icon="fluent:delete-16-regular" style={{ color: "white", width: 20, height: 20 }} />
                                </div>
                            </Button>
                        </Popconfirm>
                    </>
            })
        })
        setList(tempList)
        searchNameRef.current = name

        setLoading(false)
    }

    const onNewsFinish = async (values) => {
        let param = {
            id: values.id ? values.id : ""
        }

        let body = {
            "image": imageURL.imageUrl ? imageURL.imageUrl : "",
            "name": values.name ? values.name : "",
            "isActive": values.isActive ? 1 : 0,
        }
        // console.log("body : ", body)

        if (modalAdsBanner.title === "add") {
            const result = await insertAdsBannersFetch(null, body, accessToken)
            if (result.isSuccess) {
                Notification('success', 'สร้างสำเร็จ')
            } else {
                Notification('error', 'ไม่สามารถสร้างได้ กรุณาลองใหม่อีกครั้ง')
            }
        } else if (modalAdsBanner.title === "edit") {
            const result = await updateAdsBannersFetch(param, body, accessToken)
            if (result.isSuccess) {
                Notification('success', 'เเก้ไขสำเร็จ')
            } else {
                Notification('error', 'ไม่สามารถเเก้ไขได้ กรุณาลองใหม่อีกครั้ง')
            }
        }

        // reload
        getAdsBannersAll("")

        // set default
        setFormAdsBannerDefault()
    }

    // const onPropertyGalleryFinish = async (values) => {

    // }

    const onSearchFinish = async (values) => {
        let name = values?.name ? values.name : ""
        await getAdsBannersAll(name)
    }

    const handleAdsBannersDelete = async (id) => {
        let param = {
            id
        }
        const result = await deleteAdsBannersByIdFetch(param, null, accessToken)
        if (result.isSuccess) {
            Notification('success', 'ลบสำเร็จ')
        } else {
            Notification('error', 'ไม่สามารถลบได้ กรุณาลองใหม่อีกครั้ง')
        }
    }

    const onPagine = (n) => {
        pageCurrentRef.current = n.current
        getAdsBannersAll(searchNameRef.current)
    }

    const setFormAdsBannerDefault = () => {
        formAdsBanner.setFieldsValue({
            id: undefined, // 
            name: undefined, // 
            isActive: undefined, // 
        })

        setImageURL({
            loading: false,
            imageUrl: null
        })

        setModalAdsBanner({
            isShow: false,
            title: null
        })
    }

    const getBaseApi = async () => {
        getAdsBannersAll("")
    }

    useEffect(() => {
        // console.log("accessToken : ", accessToken)
        getBaseApi()
    }, [])

    return (
        <Row>
            <Col span={12}>
                <label>จัดการโฆษณา</label>
            </Col>

            <Col span={12} style={{ paddingBottom: 20 }}>
                <Form form={formSearch} layout="vertical" onFinish={onSearchFinish}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                        <div style={{ paddingLeft: 10 }}>
                            <Form.Item
                                // label="ชื่อโฆษณา"
                                name="name"
                                style={{ width: '100%' }}
                            >
                                <Input placeholder="ค้นหาโฆษณา" />
                            </Form.Item>
                        </div>

                        {/* <div style={{ paddingLeft: 10 }}>
                            <Form.Item
                                label="ช่วงวันที่สร้าง" name="dateRange"
                                style={{ width: '100%' }}
                            >
                                <RangePicker />
                            </Form.Item>
                        </div> */}

                        <div style={{ paddingLeft: 10, marginTop: -24 }}>
                            <Button
                                style={{ float: 'right', width: 70 }}
                                type="primary"
                                onClick={() => formSearch.submit()}
                            >
                                ค้นหา
                            </Button>
                        </div>
                    </div>
                </Form>
            </Col>

            <Col span={24} style={{ paddingBottom: 20 }}>
                <Button
                    type="primary"
                    style={{ float: 'right' }}
                    onClick={() => {
                        formAdsBanner.resetFields()

                        setModalAdsBanner({
                            isShow: true,
                            title: "add"
                        })
                    }}
                >เพิ่มรายการ</Button>
            </Col>

            <Col span={24}>
                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={list}
                    pagination={{
                        current: pageCurrentRef.current,
                        pageSize: pageSize,
                        total: total
                    }}
                    onChange={(n) => onPagine(n)}
                >
                </Table>
            </Col>

            <Modal
                title={<strong><label className="topic-color-bold">{modalAdsBanner.title === "add" ? "เพิ่มข้อมูล" : "เเก้ไขข้อมูล"}</label></strong>}
                visible={modalAdsBanner.isShow}
                zIndex={999}
                onCancel={() => {

                    // default
                    setFormAdsBannerDefault()
                }}
                width={"90%"}
                onOk={() => {
                    formAdsBanner.submit()
                }}
                okText={<label style={{ width: 50, cursor: 'pointer' }}>บันทึก</label>}
                cancelText={<label style={{ width: 50, cursor: 'pointer' }}>ยกเลิก</label>}
            >
                <Form
                    layout="vertical"
                    form={formAdsBanner}
                    onFinish={onNewsFinish}
                >
                    <Row gutter={[24, 0]}>
                        <Col xs={24} md={12} xl={12}>
                            <Row gutter={[24, 0]}>
                                <Col span={24}>
                                    <Form.Item name="id" style={{ display: "none" }}>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item name="adsBannerId" style={{ display: "none" }}>
                                        <Input />
                                    </Form.Item>

                                    <Form.Item
                                        name="name"
                                        label="ชื่อโฆษณา"
                                        rules={[{ required: true, message: 'กรุณากรอกชื่อโฆษณา' }]}
                                    >
                                        <Input placeholder='ชื่อโฆษณา'/>
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        name="isActive"
                                        label="เเสดงข่าวหรือไม่ ?"
                                        rules={[{ required: true, message: 'กรุณาเลือกสถานะการเเสดง' }]}
                                    >
                                        <Select
                                            showSearch
                                            style={{ width: '100%' }}
                                            optionFilterProp="children"
                                            allowClear
                                        >
                                            <Option key={0} value={true}>เปิด</Option>
                                            <Option key={1} value={false}>ปิด</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>

                        <Col xs={24} md={12} xl={12}>
                            <Row gutter={[24, 0]}>
                                <Col xs={24} md={12} xl={12}>
                                    <div style={{ display: "grid" }}>
                                        <label style={{ paddingBottom: 6 }}>ภาพปกโฆษณา</label>
                                        {imageURL?.imageUrl ?
                                            <img
                                                style={{ borderRadius: 8, maxWidth: "100%", border: "1px solid #EEEEEE" }}
                                                src={`${imageURL.imageUrl}`}
                                            />
                                            :
                                            <img
                                                style={{ width: "100%", borderRadius: 8, border: "1px solid #C4C4C4" }}
                                                src={`./assets/images/default/df-img.png`}
                                            />
                                        }
                                        <div style={{ paddingTop: 24, paddingBottom: 24 }}>
                                            <Upload
                                                {...optionAdsBannerImage}
                                                accept='image/jpeg, image/png, image/jfif'
                                                style={{ width: "100%" }}
                                                maxCount={1}
                                                showUploadList={false}
                                            >
                                                <Button
                                                    type="default"
                                                    style={{ width: "100%" }}
                                                    icon={imageURL.loading ? <LoadingOutlined /> : <UploadOutlined />}
                                                >อัพโหลดรูปภาพ</Button>
                                            </Upload>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </Row>
    )
}
