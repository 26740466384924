/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */

import { useState } from "react"
import "../footer/css/index.scss"
import { Flex } from "antd"
import { useLocation, useNavigate } from 'react-router-dom'
import { Icon } from '@iconify/react'
import { useDimensions } from "../../mamager/ScreenManager"

function Footer () {

    const navigate = useNavigate()
    const { width } = useDimensions()

    return (
        <footer>
            <div
                style={{
                    paddingTop: 48,
                    paddingBottom: 48,
                    background: '#181818',
                }}
                className='front-footer'
            >
                <div className='container' style={{ paddingLeft: 24, paddingRight: 24 }}>
                    <div className='footer-content'>
                        <div className="title">KK WEEKLY NEWS</div>
                        <div className="address"><span className='address-icon'></span>303 หมู่ 12 ต.บ้านเป็ด อ.เมือง จ.ขอนแก่น</div>
                        
                        <div 
                            className="phone" 
                            onClick={() => {
                                window.open("tel:+66938892644");
                            }}
                        >
                            <div className="phone-icon"><img src="/assets/images/logo/phone-logo.png"></img></div>
                            <div className="phone-info">
                                <div className="phone-info-container">
                                    <label className='line1'>โทร</label><br />
                                    <label className='line2'>093-889-2644</label>
                                </div>
                            </div>
                        </div>

                        <div className='social-line'>
                            <a href="https://www.facebook.com/profile.php?id=61558506964145&mibextid=ZbWKwL" target="_blank"><div className="facebook-icon"></div></a>
                            <a href="https://line.me/ti/p/QQL5Ck4hjo" target="_blank"><div className="line-icon"></div></a>
                            <a href="tel:080-559-9082" target="_blank"><div className="call-phone-icon"></div></a>
                        </div>
                    </div>
                </div>
            </div>

            <nav style={{ backgroundColor: "#99251C", color: "white", paddingTop: 15, paddingBottom: 15, fontWeight: 600, textAlign: 'center' }}>
                <div className='container'>
                    <label>Copyright © 2024 KK WEEKLY NEWS All Right Reserved.</label>
                </div>
            </nav>
        </footer>
    )
}

export default Footer
