/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import "../header/css/index.css"
// import styled from 'styled-components'
import { useState } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import {
	Drawer,
	Button,
	Menu,
} from 'antd'
import { Icon } from '@iconify/react'
import { useDimensions } from "../../mamager/ScreenManager"
import { scroller } from "react-scroll"
import { serverUrl } from "../../../constants"

// const CustomMenu = styled(Menu)`
// 	background-color: rgba(0, 0, 0, 0);
// 	color: white;
// `

export default function Header (props) {
	const navigate = useNavigate()
	const location = useLocation()

	const { width } = useDimensions()

	const [state, setState] = useState({
		current: 'mail',
		visible: false
	})

	const showDrawer = () => {
		setState({
			visible: true
		})
	}

	const onClose = () => {
		setState({
			visible: false
		})
	}

	const genRightMenu = () => {
		return (
			<Menu mode="inline">
				<Menu.Item
					key="1"
					onClick={() => {
						openPath("news")
					}}
				>
					ข่าว
				</Menu.Item>

				<Menu.Item
					key="2"
					onClick={() => {
						navigate("/about-us")
					}}
				>
					เกี่ยวกับเรา
				</Menu.Item>
			</Menu>
		)
	}

	const openPath = (menu) => {
		let path = location.pathname
		if (menu === "news") {
			if (path === "/" || path === "/home") {
				scroller.scrollTo("news", {
					duration: 1500,
					delay: 100,
					smooth: true,
					offset: -210,
				})
			} else {
				navigate("/?menu=news")
			}
		}
	}

	return (
		<div
			style={{
				paddingTop: 0,
				paddingBottom: 0,
				position: 'fixed',
				zIndex: 2,
				boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.4)',
				width: '100%',
				backgroundColor: '#D9D9D9',
				overflow: 'hidden',
				height: 70
			}}
		>
			<div 
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
					paddingLeft: width <= 992 ? 0 : null,
					paddingRight: width <= 992 ? 0 : null
				}}
			>
				<div style={{ display: "flex", alignItems: "center" }}>
					<div style={{ display: "grid", alignItems: "center", color: "white" }}>
						<img
							src={"/assets/images/logo/kk-logo.jpg"}
							style={{ height: '70px', cursor: "pointer" }}
							onClick={() => {
								navigate("/")
							}}
						/>
					</div>
					{width > 992 ?
						<div style={{ display: "flex", alignItems: "center" }}>
							<div
								onClick={() => {
									openPath("news")
								}}
								className="divStyle"
								style={{ marginLeft: 20, minWidth: 96 }}
							>
								<div style={{ color: "black", cursor: "pointer" }}>
									<label style={{ padding: 12, fontSize: 20, cursor: "pointer" }}>ข่าว</label>
								</div>
							</div>
							<div
								onClick={() => {
									navigate("/about-us")
								}}
								className="divStyle"
								style={{ minWidth: 96 }}
							>
								<div style={{ color: "black", cursor: "pointer" }}>
									<label style={{ padding: 12, fontSize: 20, cursor: "pointer" }}>เกี่ยวกับเรา</label>
								</div>
							</div>
						</div>
						:
						<div>
						</div>

					}

				</div>

				{width > 992 ?
					<div
						style={{
							display: "flex",
							paddingTop: 2,
							alignItems: "center",
							justifyContent: "flex-end"
						}}
					>
						{/* {genLeftMenu()} */}
					</div>
					:
					<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
						<Drawer
							title={<label style={{ fontSize: 16, fontWeight: 100 }}>KK WEEKLY NEWS</label>} placement="right"
							closable={false}
							onClose={onClose}
							visible={state.visible}
							bodyStyle={{ padding: '0px' }}
							size="default"
						>
							{genRightMenu()}
						</Drawer>

						<Button
							type="primary"
							onClick={showDrawer}
							style={{
								height: 70,
								width: 75,
								borderRadius: 0
							}}
						>
							<Icon
								icon="charm:menu-hamburger"
								style={{
									fontSize: '2.5em',
									marginTop: 4,
									marginLeft: 0,
									color: "white"
								}}
							/>
						</Button>
					</div>
				}
			</div>
		</div>
	)
}
