


import './css/index.scss'

import Header from '../../common/components/header'
import Footer from '../../common/components/footer'

import { Input, Row, Col, Button, Flex, Pagination, ConfigProvider } from "antd"

import moment from 'moment'
import 'moment/locale/th'

import { useDimensions } from "../../common/mamager/ScreenManager"


const About = () => {

    const { width } = useDimensions()
  return (
    <>
        <Header />

        <div style={{ paddingTop: 70, paddingBottom: 0, background: '#EEEEEE', display: 'block'}}>
        
            <div className='container'>
                <div className='about-container'>
                { width >= 768 ? 
                    <Flex wrap gap="middle" justify='space-between' align='flex-start'>
                        <div>
                            <img src="/assets/images/about_us/office.jpg"></img>
                        </div>
                        <div>
                            <div className='title'>เกี่ยวกับเรา</div>
                            <div className='detail'>
                                สำนักข่าวของเรา ตั้งอยู่ในจังหวัดขอนแก่น มีความมุ่งมั่นที่จะนำเสนอข่าวสารที่ทันเหตุการณ์ และเชื่อถือได้ เพื่อบริการชุมชนท้องถิ่นของเรา ด้วยทีมงานมืออาชีพที่ทำงานด้วยความตั้งใจและรวดเร็ว เรามุ่งมั่นที่จะเป็นแหล่งข้อมูลที่ชาวขอนแก่นสามารถพึ่งพาได้ ไม่ว่าจะเป็นข่าวสารการเมือง เศรษฐกิจ สังคม หรือเหตุการณ์สำคัญอื่น ๆ ที่เกิดขึ้นในท้องถิ่น เราพร้อมนำเสนอข่าวสารที่ตรงไปตรงมาและเป็นกลาง เพื่อให้ประชาชนได้รับข้อมูลที่ถูกต้องและครบถ้วนในทุกด้าน
                                <br/><br/>

                                แต่ยังเน้นความถูกต้องและความชัดเจนในทุกข่าวที่เรานำเสนอ เราเชื่อว่าการสื่อสารที่ดีจะช่วยส่งเสริมความเข้าใจและความร่วมมือในชุมชน ซึ่งเป็นเป้าหมายสำคัญของเราที่จะทำให้ขอนแก่นเป็นเมืองที่เข้มแข็งและเจริญรุ่งเรือง
                                <br/><br/>

                                ขอขอบคุณที่ไว้วางใจในสำนักข่าวของเรา เราสัญญาว่าจะทำงานด้วยความมุ่งมั่นและพัฒนาอย่างต่อเนื่อง เพื่อบริการข่าวสารที่ดีที่สุดสำหรับชุมชนของเรา
                            </div>
                        </div>
                    </Flex>
                :
                    <div className='mobile-detail'>
                        <div className='image-container'>
                            <img src="/assets/images/about_us/office.jpg"></img>
                        </div>
                        <div className='detail-container'>
                            <div className='title'>เกี่ยวกับเรา</div>
                            <div className='detail'>
                                สำนักข่าวของเรา ตั้งอยู่ในจังหวัดขอนแก่น มีความมุ่งมั่นที่จะนำเสนอข่าวสารที่ทันเหตุการณ์ และเชื่อถือได้ เพื่อบริการชุมชนท้องถิ่นของเรา ด้วยทีมงานมืออาชีพที่ทำงานด้วยความตั้งใจและรวดเร็ว เรามุ่งมั่นที่จะเป็นแหล่งข้อมูลที่ชาวขอนแก่นสามารถพึ่งพาได้ ไม่ว่าจะเป็นข่าวสารการเมือง เศรษฐกิจ สังคม หรือเหตุการณ์สำคัญอื่น ๆ ที่เกิดขึ้นในท้องถิ่น เราพร้อมนำเสนอข่าวสารที่ตรงไปตรงมาและเป็นกลาง เพื่อให้ประชาชนได้รับข้อมูลที่ถูกต้องและครบถ้วนในทุกด้าน
                                <br/><br/>

                                แต่ยังเน้นความถูกต้องและความชัดเจนในทุกข่าวที่เรานำเสนอ เราเชื่อว่าการสื่อสารที่ดีจะช่วยส่งเสริมความเข้าใจและความร่วมมือในชุมชน ซึ่งเป็นเป้าหมายสำคัญของเราที่จะทำให้ขอนแก่นเป็นเมืองที่เข้มแข็งและเจริญรุ่งเรือง
                                <br/><br/>

                                ขอขอบคุณที่ไว้วางใจในสำนักข่าวของเรา เราสัญญาว่าจะทำงานด้วยความมุ่งมั่นและพัฒนาอย่างต่อเนื่อง เพื่อบริการข่าวสารที่ดีที่สุดสำหรับชุมชนของเรา
                            </div>
                        </div>
                    </div>
                }
                </div>
            </div>
        
        </div>

        <Footer />
    </>
  );
};

export default About;